import React from 'react';
import { FintechMainSection } from 'views/fintech/fintech-main-section';
import { FintechRowsSection } from 'views/fintech/fintech-rows-section';
import { FooterSection } from 'modules/footer-section';
import { ObjectiveAdvantagesSection } from 'modules/objective-advantages-section';
import { PerksWithImageSection } from 'modules/perks-with-image-section';
import { array } from 'prop-types';

import {
    ADVANTAGES,
    ADVANTAGES_FOOTER_LINKS,
    LATEST_RELATED_ARTICLES_SETTINGS,
    PERKS,
} from './constants';
import { FintechBankingSection } from './fintech-banking-section';
import { FintechCaseStudiesSection } from './fintech-case-studies-section';
import { FintechCtaSectionExtended } from './fintech-cta-section-extended';
import { FintechDigitalSection } from './fintech-digital-section';
import { AIAssistantSection } from 'modules/ai-assistant-section';
import { WhereNeedSection } from './fintech-where-need-section';
const Props = {
    faqData: array.isRequired,
};

export const FintechView = ({ faqData }) => {
    return (
        <>
            <FintechMainSection />
            <FintechCaseStudiesSection />
            <AIAssistantSection />
            <FintechRowsSection />
            <WhereNeedSection />
            <FintechDigitalSection />
            <FintechCtaSectionExtended />
            <ObjectiveAdvantagesSection
                title="fintech-page.objectiveAdvantages.title"
                subTitle="fintech-page.objectiveAdvantages.subTitle"
                rootPath="fintech-page.objectiveAdvantages"
                footer="fintech-page.objectiveAdvantages.footer"
                footerLinks={ADVANTAGES_FOOTER_LINKS}
                data={ADVANTAGES}
            />
            <PerksWithImageSection
                title="fintech-page.deliverSolutions"
                perks={PERKS}
            />
            <FintechBankingSection />
            <FooterSection
                faqSection={{
                    title: faqData.title,
                    data: faqData.data,
                    parseRawAnswer: true,
                }}
                latestRelatedArticles={LATEST_RELATED_ARTICLES_SETTINGS}
            />
        </>
    );
};

FintechView.propTypes = Props;
